import type { ArticleFieldsFragment } from '@news/gql';
import { NodeType, isNotNullish } from '@news/lib';

import { renderNode } from 'lib/bodyRenderer';
import type { NodeMap } from 'lib/richText';
import { toBrTags } from 'lib/toBrTags';

interface Props {
  body: ArticleFieldsFragment['articleLeadRichText'];
  data?: ArticleFieldsFragment['data'];
}

const articleLeadNodeMap: Partial<NodeMap> = {
  [NodeType.Text]: ({ children }) => <strong>{toBrTags(children)}</strong>,
};

export const ArticleLeadWrapper = ({ body, data }: Props) => (
  <div>
    {renderNode({
      node: body,
      options: {
        data: (data ?? []).filter(isNotNullish),
        nodeMap: articleLeadNodeMap,
      },
    })}
  </div>
);
