import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import type { Maybe } from '@news/gql';
import { Ratio } from '@news/gql';

import { LazyImage } from 'components/LazyImage';
import { ratioString } from 'styles/ratio';
import { theme } from 'styles/theme';

type Props = {
  src: Maybe<string>;
  title: string;
};

export const Thumbnail = ({ src, title }: Props) => {
  return (
    <ThumbnailWrapper>
      <LazyImage src={src} alt={title} width={120} contain={false} ratio={Ratio.Ratio_16x9} />
    </ThumbnailWrapper>
  );
};

const ThumbnailWrapper = styled.div`
  width: 120px;
  aspect-ratio: ${ratioString(Ratio.Ratio_16x9)};
  background-color: ${colors.black[10]};
  border-radius: 4px;
  overflow: hidden;
  ${theme.mq.tablet} {
    width: 150px;
  }
`;
