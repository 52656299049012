import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { usePlayNextContentContext } from 'contexts/PlayNextContentContext';
import { useActiveVideoPlayerContext } from 'contexts/VideoPlayerContext';

const AvodPlayerWrapper = dynamic(() => import('./AvodPlayerWrapper').then((module) => module.AvodPlayerWrapper), {
  ssr: false,
});

export const FIXED_ONLY_VIDEO_PLAYER_ID = 'fixed-only-video-player-id';

export const FixedOnlyVideoPlayer = () => {
  const { activeVideoPlayerId } = useActiveVideoPlayerContext();
  const { nowPlaying, isNextContentMutedRef } = usePlayNextContentContext();

  const isActive = activeVideoPlayerId === FIXED_ONLY_VIDEO_PLAYER_ID && nowPlaying;

  if (!isActive) {
    return null;
  }

  return (
    <StyledAvodPlayerWrapper
      videoAsset={nowPlaying}
      shouldStartMuted={isNextContentMutedRef?.current ?? false}
      isFixedOnly={true}
    />
  );
};

const StyledAvodPlayerWrapper = styled(AvodPlayerWrapper)`
  height: 0;
`;
