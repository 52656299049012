import { useEffect, useState } from 'react';

export const use16by9Dimensions = () => {
  const [state, setState] = useState(() => getDimensions());

  useEffect(() => {
    const handleResize = () => {
      setState(getDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getDimensions() {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const isLandscape = screenWidth > screenHeight;
    const aspectRatio = 16 / 9;

    const width = isLandscape ? screenHeight * aspectRatio : screenWidth;
    const height = isLandscape ? screenHeight : screenWidth / aspectRatio;

    return { width, height, isLandscape };
  }

  return state;
};
