import Link from 'next/link';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';
import { Chevron } from 'components/icons';
import { theme } from 'styles/theme';

interface Props {
  label: string;
  feedSlug?: string;
  headerBackgroundColor?: string;
  className?: string;
}

export const GroupHeading = ({ label, feedSlug, headerBackgroundColor, className }: Props) => {
  return (
    <GroupHeadingLinkComponent feedSlug={feedSlug}>
      <GroupHeadingContainer as="h2" variant="title3" className={className} $backgroundColor={headerBackgroundColor}>
        <GroupHeadingRedDot role="presentation" />
        <GroupHeadingLabel>{label}</GroupHeadingLabel>
      </GroupHeadingContainer>
    </GroupHeadingLinkComponent>
  );
};

export const AdGroupHeading = () => {
  return (
    <GroupHeadingContainer as="h2" variant="title3" $backgroundColor={colors.yellow.soft}>
      <GroupHeadingLabel>ANNONS</GroupHeadingLabel>
    </GroupHeadingContainer>
  );
};

type GroupHeadingLinkProps = React.PropsWithChildren<{
  feedSlug?: string;
}>;

const GroupHeadingLinkComponent = ({ feedSlug, children }: GroupHeadingLinkProps) => {
  if (!feedSlug) {
    return children;
  }
  return (
    <GroupHeadingLink href={feedSlug}>
      {children}
      <GroupHeadingShowAll variant="nav-link-text" as="span">
        Visa alla <Chevron size={16} />
      </GroupHeadingShowAll>
    </GroupHeadingLink>
  );
};

const GroupHeadingLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${colors.black[100]};

  &:hover {
    text-decoration: none;
  }
`;

const GroupHeadingContainer = styled(Typography)<{ $backgroundColor?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${colors.black[100]};
  padding: ${sizeUnits[16]};
  background-color: ${({ $backgroundColor }) => $backgroundColor && $backgroundColor};
`;

const GroupHeadingLabel = styled.span`
  text-transform: uppercase;
`;

const GroupHeadingRedDot = styled.span`
  width: 4px;
  height: 4px;
  margin-bottom: 2px;
  background-color: red;
  margin-right: 4px;
  ${theme.mq.tablet} {
    margin-right: 8px;
  }
`;

const GroupHeadingShowAll = styled(Typography)`
  padding-right: ${sizeUnits[16]};
  white-space: nowrap;
  line-height: 1;
  display: flex;
  align-items: center;
`;
