import Link from 'next/link';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { RelatedArticleFieldsFragment, VideoPlayerDataFieldsFragment } from '@news/gql';
import type { RelatedItem as TRelatedItem } from '@news/lib';
import {
  FALLBACK_ARTICLE_IMAGE,
  getImageFromFeatured,
  getItemUrlForFeedItem,
  getRelatedHumanPublishedAt,
  getRelatedTitle,
} from '@news/lib';

import { PlayIconOverlay } from 'components/PlayIconOverlay';
import { Thumbnail } from 'components/Thumbnail';
import { Typography } from 'components/Typography';
import { lineClamp } from 'styles/mixins/lineClamp';

interface Props {
  item: TRelatedItem;
}

export const RelatedItem = ({ item }: Props) => {
  const publishedAt = getRelatedHumanPublishedAt(item);

  const link = getItemUrlForFeedItem(item);

  return (
    <RelatedElementWrapper href={link}>
      <ThumbnailWrapper>
        {item.__typename === 'RelatedArticle' && <RelatedArticleThumbnail article={item} />}
        {item.__typename === 'VideoPlayerData' && <VideoAssetThumbnail videoPlayerData={item} />}
      </ThumbnailWrapper>
      <ContentWrapper>
        <Title variant="title4" as="h3">
          {getRelatedTitle(item)}
        </Title>
        {publishedAt && (
          <PublishedAt variant="metadata" as="time" dateTime={publishedAt}>
            {publishedAt}
          </PublishedAt>
        )}
      </ContentWrapper>
    </RelatedElementWrapper>
  );
};

interface RelatedArticleThumbnailProps {
  article: RelatedArticleFieldsFragment;
}

const RelatedArticleThumbnail = ({ article }: RelatedArticleThumbnailProps) => {
  switch (article.featuredAsset?.__typename) {
    case 'VideoPlayerData':
      return (
        <>
          <Thumbnail
            src={getImageFromFeatured(article.featuredAsset) || FALLBACK_ARTICLE_IMAGE}
            title={article.title}
          />
          <PlayIconOverlay />
        </>
      );
    case 'Image':
      return <Thumbnail src={article.featuredAsset.url} title={article.title} />;
    default:
      return null;
  }
};

interface VideoAssetThumbnailProps {
  videoPlayerData: VideoPlayerDataFieldsFragment;
}

const VideoAssetThumbnail = ({ videoPlayerData }: VideoAssetThumbnailProps) => {
  return (
    <>
      <Thumbnail
        src={getImageFromFeatured(videoPlayerData) || FALLBACK_ARTICLE_IMAGE}
        title={videoPlayerData.caption || videoPlayerData.asset?.title || ''}
      />
      <PlayIconOverlay />
    </>
  );
};

const Title = styled(Typography)`
  ${lineClamp(2)}
`;

const RelatedElementWrapper = styled(Link)`
  display: flex;
  color: inherit;

  &:hover {
    text-decoration: none;
  }

  &:hover ${Title} {
    text-decoration: underline;
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
`;

const ContentWrapper = styled.div`
  padding: 0 ${sizeUnits[16]};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

const PublishedAt = styled(Typography)`
  color: ${colors.black[50]};
`;
