import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { SponsorFieldsFragment } from '@news/gql';

import { LazyImage } from 'components/LazyImage';
import { Typography } from 'components/Typography';
import { generateSrcSet } from 'lib/helpers';
import { TABLET, theme } from 'styles/theme';

interface Props {
  sponsors: SponsorFieldsFragment[];
}

export const Sponsors = ({ sponsors }: Props) => {
  return (
    <SponsorsWideWrapper>
      <Typography variant="body2">Sponsorer</Typography>
      <SponsorContainer>
        {sponsors.map((s) => (
          <Sponsor sponsor={s} key={`sponsor-${s.id}`} />
        ))}
      </SponsorContainer>
    </SponsorsWideWrapper>
  );
};

/** @internal */
export const Sponsor = ({ sponsor }: { sponsor: SponsorFieldsFragment }) => {
  const srcSet = generateSrcSet({ imageSrc: sponsor.image, sizes: [120, 180] });
  const sizes = `(min-width: ${TABLET}px) 180px, 120px`;

  return (
    <SponsorLink target="_blank" href={sponsor.link ?? ''} rel="noopener noreferrer" aria-label="Länk till sponsor">
      <LazyImage
        alt={sponsor.title}
        background={colors.white[100]}
        width={180}
        sizes={sizes}
        src={sponsor.image}
        srcSet={srcSet}
      />
    </SponsorLink>
  );
};

const SponsorsWideWrapper = styled.div`
  text-align: left;
`;

const SponsorLink = styled.a`
  display: flex;
  justify-content: center;
  background-color: ${colors.white[100]};
  border: 1px solid ${colors.gray.light};
  width: 60px;
  height: 35px;
  padding: 2px;
  ${theme.mq.tablet} {
    width: 90px;
    height: 50px;
  }
`;

const SponsorContainer = styled.div`
  margin-top: ${sizeUnits[4]};
  display: flex;
  gap: ${sizeUnits[4]};
`;
