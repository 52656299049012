import styled from 'styled-components';
import { ItemTracker } from 'tracking/ItemTracker';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { ArticleFieldsFragment, ArticleInFeedFieldsFragment } from '@news/gql';
import type { RelatedItem as TRelatedItem } from '@news/lib';
import { isNotNullish, isSponsoredArticle, isValidRelatedItem } from '@news/lib';
import { GroupId } from '@news/tracking';

import { Typography } from 'components/Typography';
import { theme } from 'styles/theme';

import { RelatedItem } from './RelatedItem';

interface Props {
  article: ArticleFieldsFragment | ArticleInFeedFieldsFragment;
}

export const RelatedItemList = ({ article }: Props) => {
  const relatedItems = article.related.filter((item): item is TRelatedItem => {
    // The editors should never add sponsored articles to the related list.
    // In case they do, we should filter them out rather than rendering a sponsored article as a normal article (illegal)
    return isValidRelatedItem(item) && isNotNullish(item) && !isSponsoredArticle(item);
  });

  if (relatedItems.length === 0) {
    return null;
  }

  return (
    <RelatedItemsWrapper>
      <RelatedText variant="title3-strong" as="h3">
        Relaterade artiklar
      </RelatedText>
      {relatedItems.map((item, index) => (
        <ItemTracker
          key={item.id}
          item={item}
          positionData={{
            groupId: GroupId.RelatedArticle(article.id),
            index: index,
            kilkayaPositionId: 'feed',
          }}
        >
          <RelatedItem key={item.id} item={item} />
        </ItemTracker>
      ))}
    </RelatedItemsWrapper>
  );
};

const RelatedItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizeUnits[8]};
  width: 100%;
  padding: ${sizeUnits[16]};
  padding-top: 0;

  ${theme.mq.tablet} {
    gap: ${sizeUnits[16]};
  }
`;

const RelatedText = styled(Typography)`
  color: ${colors.red.tv4};
`;
