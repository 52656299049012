import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from '@news/lib';

import RotateDeviceIcon from '../../assets/icons/rotate-device.svg';
import { use16by9Dimensions } from './use16by9Dimensions';

export function ElectionMap() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MapWrapper>
        <StyledIframe src="/election-map.html" frameBorder="0" allowFullScreen scrolling={isOpen ? 'yes' : 'no'} />
        <Overlay onClick={() => setIsOpen(true)} />
      </MapWrapper>
      {isOpen && <MapModal onClose={() => setIsOpen(false)} />}
    </>
  );
}

function MapModal({ onClose }: { onClose(): void }) {
  const { width, isLandscape } = use16by9Dimensions();
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent
        style={{
          width: isLandscape ? width : '100%',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ModalIframeContainer>
          <iframe
            src="/election-map.html"
            title="election-map"
            frameBorder="0"
            allowFullScreen
            style={{ width: '100%', height: '100%' }}
          />
        </ModalIframeContainer>
      </ModalContent>

      <StyledRotateDeviceIcon />
    </ModalOverlay>
  );
}

/* Styled Components */

const MapWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;

  /* Pseudo-element for hover effect */
  &:hover::after {
    content: 'Klicka för att förstora';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0 0 0 / 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
    pointer-events: none;
  }

  /* Overlay to capture clicks and hover events */
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const StyledIframe = styled.iframe`
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0 0 0 / 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 1280px;
  aspect-ratio: 16 / 9;
  background-color: ${colors.white.full};
  overflow: hidden;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 2;
  background: transparent;
  border: none;
  font-size: 32px;
  color: gray;
  cursor: pointer;
`;

const ModalIframeContainer = styled.div`
  width: 100%;
  height: 100%;
`;
/* Rotate Device Animation */

const wiggleAnimation = keyframes`
  0%, 20% { 
    transform: rotate(0deg);
  }
  40% { 
    transform: rotate(-20deg);
  }
  60% { 
    transform: rotate(-40deg);
  }
  80% { 
    transform: rotate(-20deg);
  }
   100% { 
    transform: rotate(0deg);
  }
`;

const fadeAnimation = keyframes`
  0%, 20% { 
    opacity: 0;
  }
  20%, 95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledRotateDeviceIcon = styled(RotateDeviceIcon)`
  opacity: 0;
  position: absolute;
  fill: ${colors.grey.light};
  width: 50px;
  height: auto;
  top: 20%;
  animation:
    ${wiggleAnimation} 1s linear 2 1.5s,
    ${fadeAnimation} 5s linear 1 1.5s;
  animation-fill-mode: forwards;

  @media (orientation: landscape) {
    display: none;
  }
`;
