import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { SocialButton } from 'components/buttons';
import { FacebookIcon, InstagramIcon, TwitterIcon } from 'components/icons';

interface ISocialMediaIconProps {
  instagramUrl?: string;
  twitterUrl?: string;
  facebookUrl?: string;
}

export function SocialMediaIcons({ instagramUrl, twitterUrl, facebookUrl }: ISocialMediaIconProps) {
  return (
    <>
      {instagramUrl && (
        <SocialMediaButton
          as="a"
          title="Följ på instagram"
          aria-label="Följ på instagram"
          href={instagramUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon size={24} color="currentColor" />
        </SocialMediaButton>
      )}
      {facebookUrl && (
        <SocialMediaButton
          as="a"
          title="Följ på facebook"
          aria-label="Följ på facebook"
          href={facebookUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon size={24} color="currentColor" />
        </SocialMediaButton>
      )}
      {twitterUrl && (
        <SocialMediaButton
          as="a"
          title="Följ på twitter"
          aria-label="Följ på twitter"
          href={twitterUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon size={24} color="currentColor" />
        </SocialMediaButton>
      )}
    </>
  );
}

const SocialMediaButton = styled(SocialButton)`
  width: ${sizeUnits[40]};
  height: ${sizeUnits[40]};
`;
