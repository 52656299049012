import { useCallback, useEffect, useState } from 'react';

const isClient = typeof window !== 'undefined';
const getMatches = (query: string): boolean => (isClient ? window.matchMedia(query).matches : false);

type Unsubscribe = () => void;
type Subscribe = (mediaQueryList: MediaQueryList, callback: () => void) => Unsubscribe;
const subscribe: Subscribe = (mediaQueryList, callback) => {
  if (mediaQueryList.addListener) {
    mediaQueryList.addListener(callback);
    return () => mediaQueryList.removeListener(callback);
  } else {
    mediaQueryList.addEventListener('change', callback);
    return () => mediaQueryList.removeEventListener('change', callback);
  }
};

export function useMatchMedia(query: string) {
  const [matches, setMatches] = useState<boolean>(false);
  const onChange = useCallback(() => setMatches(getMatches(query)), [query]);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    onChange();
    return subscribe(mediaQueryList, onChange);
  }, [onChange, query]);

  return matches;
}
