import { useEffect } from 'react';

import type { ArticleFieldsFragment, VideoAssetFieldsFragment } from '@news/gql';
import type { Unpacked } from '@news/lib';

import {
  ActionTypes,
  type VideoAssetWithParentFeaturedId,
  usePlayNextContentContext,
} from 'contexts/PlayNextContentContext';

type BodyData = Unpacked<ArticleFieldsFragment['data']>['data'];

const getAssetFromData = (bodyData: BodyData): VideoAssetFieldsFragment | null => {
  switch (bodyData.__typename) {
    case 'ExtendedVideoAssetData':
    case 'VideoPlayerData':
      return bodyData.asset;
    default:
      return null;
  }
};

const useAddEmbeddedVideosToPNC = (article: ArticleFieldsFragment) => {
  const { setPncList } = usePlayNextContentContext();

  useEffect(() => {
    const bodyData = article.data;
    if (!Array.isArray(bodyData) || bodyData.length === 0) return;

    const videoAssets: VideoAssetWithParentFeaturedId[] = bodyData.reduce(
      (acc: VideoAssetWithParentFeaturedId[], bd) => {
        if (!bd || !bd.data) return acc;

        const asset = getAssetFromData(bd.data);
        if (!asset) return acc;

        return acc.concat({ ...asset, parentFeaturedId: article.id });
      },
      []
    );

    if (videoAssets.length > 0) {
      setPncList({ type: ActionTypes.ADD_EMBEDDED, items: videoAssets });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useAddEmbeddedVideosToPNC };
