import dynamic from 'next/dynamic';
import { memo } from 'react';

import { AD_IN_ARTICLE_KEYWORD } from '@news/lib';

const AdPlacement = dynamic(() => import('components/ad-placements/AdPlacement').then((module) => module.AdPlacement), {
  ssr: false,
});

interface Props {
  keywords?: string[];
}

export const ArticleAdPlacement = memo(function ArticleAdPlacement({ keywords }: Props) {
  return <AdPlacement mobile="mobile2" desktop="desktop3" keywords={[AD_IN_ARTICLE_KEYWORD, ...(keywords ?? [])]} />;
});
