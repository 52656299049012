import styled from 'styled-components';

import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { ArticleFieldsFragment } from '@news/gql';

import { Typography } from 'components/Typography';
import { IconPublished, IconUpdated } from 'components/icons';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { TABLET } from 'styles/theme';

type Props = {
  article: ArticleFieldsFragment;
};

export const ArticleMeta = ({ article }: Props) => {
  const isTablet = useMatchMedia(`(min-width: ${TABLET}px)`);
  const iconSize = isTablet ? 12 : 10;

  return (
    <MetadataWrapper>
      {article.humanUpdatedDateTime && article.updatedDateTime && (
        <MetadataText variant="metadata">
          <IconUpdated size={iconSize} />
          Uppdaterad:
          <time dateTime={article.updatedDateTime}>{article.humanUpdatedDateTime}</time>
        </MetadataText>
      )}
      {article.humanPublishedDateTime && article.publishedDateTime && (
        <MetadataText variant="metadata">
          <IconPublished size={iconSize} />
          Publicerad:
          <time dateTime={article.publishedDateTime}>{article.humanPublishedDateTime}</time>
        </MetadataText>
      )}
    </MetadataWrapper>
  );
};

const MetadataWrapper = styled.p`
  display: flex;
  flex-wrap: wrap;
  gap: ${sizeUnits[8]};
  margin: 0;
  margin-top: ${sizeUnits[8]};
`;

const MetadataText = styled(Typography).attrs({ as: 'span' })`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${sizeUnits[4]};
`;
