import type { ArticleFieldsFragment, ArticleInFeedFieldsFragment } from '@news/gql';

import { FeaturedImage } from '../featured-image/FeaturedImage';
import { ImageOverlay } from '../featured-image/ImageOverlay';
import { VideoPlayer, VideoPlayerImage } from '../video-player/VideoPlayer';

interface Props {
  article: ArticleFieldsFragment | ArticleInFeedFieldsFragment;
  hideText?: boolean;
  videoPlayerId?: string;
}

export const ArticleFeaturedAsset = ({ article, hideText = false, videoPlayerId }: Props) => {
  if (!article.featuredAsset) {
    return null;
  }

  switch (article.featuredAsset.__typename) {
    case 'Image':
      if (!article.featuredAsset.url) {
        return null;
      }
      return (
        <FeaturedImage
          image={article.featuredAsset.url}
          byline={!hideText ? (article.featuredAsset.byline ?? undefined) : undefined}
          overlay={<ImageOverlay caption={!hideText ? (article.featuredAsset.caption ?? undefined) : undefined} />}
        />
      );
    case 'VideoPlayerData': {
      const videoPlayerData = article.featuredAsset;
      if (!videoPlayerData.asset) {
        return null;
      }
      if (!videoPlayerId) {
        return (
          <VideoPlayerImage
            videoAsset={videoPlayerData.asset}
            thumbnailOverride={videoPlayerData.thumbnail ?? undefined}
          />
        );
      }
      return (
        <VideoPlayer
          variant="featured"
          videoAsset={videoPlayerData.asset}
          thumbnailOverride={videoPlayerData.thumbnail ?? undefined}
          captionOverride={videoPlayerData.caption ?? undefined}
          byline={videoPlayerData.thumbnailByline ?? undefined}
          hideText={hideText}
          videoPlayerId={videoPlayerId}
        />
      );
    }
  }
};
