import Link from 'next/link';
import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import { type FeedOriginFieldsFragment } from '@news/gql';

import { Typography } from 'components/Typography';
import { PREVENT_COLLAPSE_CLASS } from 'views/feed/helpers';

export const Tags = ({ tags }: { tags: FeedOriginFieldsFragment[] }) => {
  return (
    <Container>
      <RelatedText variant="title3-strong">Relaterade ämnen</RelatedText>

      <TagsWrapper>
        {tags.map((tag) => {
          if (!tag.slug || !tag.title) {
            return null;
          }

          return (
            <Tag key={tag.id} className={PREVENT_COLLAPSE_CLASS}>
              <StyledLink href={tag.slug}>
                <Typography variant="nav-link-text">{tag.title}</Typography>
              </StyledLink>
            </Tag>
          );
        })}
      </TagsWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizeUnits[8]};
`;

const TagsWrapper = styled.ul`
  display: flex;
  gap: ${sizeUnits[8]};
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Tag = styled.li`
  white-space: nowrap;
  background-color: ${colors.gray.light};
  border-radius: ${sizeUnits[4]};
  padding: ${sizeUnits[4]};
`;

const StyledLink = styled(Link)`
  color: ${colors.black[100]};
`;

const RelatedText = styled(Typography)`
  color: ${colors.red.tv4};
`;
