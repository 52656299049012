import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { Typography } from 'components/Typography';
import { squareCardBorderRadius } from 'styles/mixins/borderRadius';
import { feedItemBoxShadow } from 'styles/mixins/boxShadow';
import { lineClamp } from 'styles/mixins/lineClamp';
import { theme } from 'styles/theme';

export const ArticleWrapper = styled.div<{ $isSponsored: boolean; $useSmallMargin: boolean }>`
  ${feedItemBoxShadow}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ $isSponsored }) => ($isSponsored ? colors.yellow.soft : colors.white[100])};
  margin-top: ${({ $useSmallMargin }) => $useSmallMargin && `-6px`};
  ${theme.mq.tablet} {
    ${squareCardBorderRadius}
    margin-top: ${({ $useSmallMargin }) => $useSmallMargin && `-2px`};
  }
  ${theme.mq.desktop} {
    margin-top: ${({ $useSmallMargin }) => $useSmallMargin && `-10px`};
  }
`;

export const ArticleContentWrapper = styled.div`
  width: 100%;
  padding: ${sizeUnits[8]} ${sizeUnits[16]};

  ${theme.mq.desktop} {
    padding: ${sizeUnits[16]};
  }
`;

export const Lead = styled(Typography)<{ $lineClamp?: number }>`
  margin-top: ${sizeUnits[8]};
  padding-right: ${sizeUnits[16]};
  color: ${colors.black[50]};
  ${({ $lineClamp }) => $lineClamp && lineClamp($lineClamp)}
`;

export const LiveLabel = styled(Typography)`
  margin-right: ${sizeUnits[8]};
  ${theme.mq.tablet} {
    margin-right: ${sizeUnits[12]};
  }
`;
