import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';

import { PlayIcon } from './icons';

export const PlayIconOverlay = () => (
  <PlayIconWrapper>
    <PlayIconCircle>
      <PlayIcon stroke="none" fill={colors.white[100]} size={14} />
    </PlayIconCircle>
  </PlayIconWrapper>
);

const PlayIconWrapper = styled.div({
  position: 'absolute',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PlayIconCircle = styled.div({
  backgroundColor: colors.white[10],
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 26,
  height: 26,
  backdropFilter: 'blur(2.5px)',
});
