import React from 'react';
import styled from 'styled-components';
import { ItemTracker } from 'tracking/ItemTracker';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';
import type { HeroFieldsFragment, SponsorFieldsFragment } from '@news/gql';
import { TV4PLAY_PROGRAM_URL } from '@news/lib';
import { appendUtmTags } from '@news/tracking';

import { Typography } from 'components/Typography';
import { PrimaryButton } from 'components/buttons';
import { BigArrow } from 'components/icons';
import { generateSrcSet } from 'lib/helpers';
import { ImageProxy } from 'lib/imageProxy';
import { feedLayout } from 'styles/mixins/feed';
import { fifthElementBackground } from 'styles/mixins/fifthElementBackground';
import { TABLET, theme } from 'styles/theme';

import { SocialMediaIcons } from './SocialMediaIcons';
import { Sponsors } from './Sponsors';

function getHeroImage(hero: HeroFieldsFragment | null): string | undefined {
  if (hero?.heroImage) {
    return hero.heroImage;
  }
  if (hero?.image) {
    return hero.image;
  }
}

interface Props {
  title: string | null;
  hero: HeroFieldsFragment | null;
  sponsors: SponsorFieldsFragment[];
}

export const Hero = React.memo(function Hero({ title, sponsors, hero }: Props) {
  const description = hero?.description ?? hero?.shortDescription;

  const imageSrc = getHeroImage(hero);
  const fallbackImage = ImageProxy.url({ url: imageSrc, width: 160 });
  const srcSet = imageSrc ? generateSrcSet({ imageSrc, sizes: [160, 280] }) : undefined;
  const sizes = `(min-width: ${TABLET}px) 280px, 160px`;

  const showSocialMedia = !!hero?.twitterUrl || !!hero?.facebookUrl || !!hero?.instagramUrl;
  const slug = hero?.program?.id ?? hero?.program?.nid;
  const tv4PlayLink = slug ? appendUtmTags(`${TV4PLAY_PROGRAM_URL}${slug}`, 'Hero') : undefined;
  const showButtons = !!tv4PlayLink || showSocialMedia;

  return (
    <HeroBackground>
      <HeroWrapper>
        <HeroGrid>
          <HeroTitle as="h1" variant="page-header">
            {title}
          </HeroTitle>

          <HeroDescription variant="body1">{description}</HeroDescription>

          {imageSrc && <HeroImage sizes={sizes} loading="lazy" src={fallbackImage} srcSet={srcSet} alt={title ?? ''} />}

          {showButtons && (
            <HeroButtons>
              {tv4PlayLink && (
                <PlayButtonItemTracker
                  item={{
                    item_id: hero?.id ?? '',
                    item_title: hero?.title ?? null,
                    item_type: 'Hero',
                    item_target_url: tv4PlayLink,
                    item_tags: null,
                  }}
                >
                  <PlayButton as="a" href={tv4PlayLink} target="_blank" rel="noopener noreferrer">
                    <Typography variant="CTA">Titta på TV4 Play</Typography>
                    <BigArrow />
                  </PlayButton>
                </PlayButtonItemTracker>
              )}
              {showSocialMedia && (
                <SocialMediaIcons
                  twitterUrl={hero?.twitterUrl ?? undefined}
                  facebookUrl={hero?.facebookUrl ?? undefined}
                  instagramUrl={hero?.instagramUrl ?? undefined}
                />
              )}
            </HeroButtons>
          )}
        </HeroGrid>
        {sponsors.length > 0 && <Sponsors sponsors={sponsors} />}
      </HeroWrapper>
    </HeroBackground>
  );
});

const HeroBackground = styled.div`
  background: ${colors.white[100]};
`;

const HeroGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${sizeUnits[8]};
  grid-column-gap: ${sizeUnits[12]};
  grid-template-areas:
    'title image'
    'description image'
    'buttons buttons';

  ${theme.mq.tablet} {
    grid-template-areas:
      'title image'
      'description image'
      'buttons image';
    gap: ${sizeUnits[16]};
  }
`;

const HeroWrapper = styled.div`
  ${feedLayout}
  padding: ${sizeUnits[16]};
  gap: ${sizeUnits[8]};

  ${theme.mq.tablet} {
    padding-top: ${sizeUnits[28]};
    padding-bottom: ${sizeUnits[28]};
  }
`;

const HeroImage = styled.img`
  grid-area: image;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  align-self: center;

  ${theme.mq.tablet} {
    height: 140px;
    width: 140px;
  }
`;

const HeroTitle = styled(Typography)`
  grid-area: title;
`;

const HeroDescription = styled(Typography)`
  grid-area: description;
`;

const HeroButtons = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: flex-end;
  gap: ${sizeUnits[8]};
`;

const PlayButton = styled(PrimaryButton)`
  width: 100%;
  height: 100%;
  display: flex;
  gap: ${sizeUnits[4]};
  ${fifthElementBackground({ backgroundWidth: 800 })}

  ${theme.mq.tablet} {
    padding: ${sizeUnits[8]} ${sizeUnits[32]};
  }
`;

const PlayButtonItemTracker = styled(ItemTracker)`
  flex: 1;
  height: 100%;

  ${theme.mq.tablet} {
    flex: unset;
  }
`;
